import { axiosClient } from '@vectroid/shared/api'
import { RouteNames } from '@vectroid/shared/const'

export function setupAxiosInterceptor() {
  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        alert('Your session has timed out! Logging out!...')
        window.location.replace(`/console${RouteNames.Logout}?redirect=${encodeURIComponent(window.location.pathname)}`)
      }
      return Promise.reject(error)
    }
  )
}
