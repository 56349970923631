import { GLOBAL_API_PLAYGROUND_COLLECTION } from '@vectroid/shared/api-collections'
import { appConfig } from '@vectroid/shared/config'
import { executePlaygroundCode } from 'modules/api-playground/utils/playground-helper'
import { useCollectionStore } from 'modules/indexes'
import { ApiCollectionList } from 'modules/indexes/components/api-collection-list'
import { useState } from 'react'
import { QueryConsole } from '../components/query-console'

function APIPlaygroundPage() {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any>(null)

  const baseUrl = appConfig.get('BASE_URL') ?? 'Invalid Base URL'

  const { activeCollection } = useCollectionStore()

  function setResponse(data: any) {
    setResult(JSON.stringify(data, null, 3))
  }

  const onExecute = async (endpoint: string, code: string) => {
    try {
      setLoading(true)
      const response = await executePlaygroundCode(code, endpoint, activeCollection.method, baseUrl)
      response && setResponse(response.data as any)
    } catch (e: any) {
      setResponse(e?.response?.data)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-full flex-1">
      <ApiCollectionList collectionList={GLOBAL_API_PLAYGROUND_COLLECTION} />
      <QueryConsole
        dynamicVariables={{}}
        activeCollection={activeCollection}
        onExecute={onExecute}
        loading={loading}
        result={result}
        baseUrl={baseUrl}
      />
    </div>
  )
}

export default APIPlaygroundPage
